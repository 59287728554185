


















import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import ProductCategory from "@/models/ProductCategory"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"

@Component
export default class CategoryTreeviewComponent extends Vue {

	lang: any = getModule(LangModule).lang
	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	selection = []

	@Prop() readonly productCategories!: ProductCategory[]
	@Prop() readonly loading!: boolean

	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }

}
